// Remove this SCSS from production
.main-content {
    // color palette
    .color-palette {
        .color-group {
            li {
                list-style-type: none;
                &.color {
                    padding: 10px 15px 11px;
                    margin: 0;
                    &.main-color {
                        .name {
                            display: block;
                            margin-bottom: 53px;
                            font-size: 2rem;
                        }
                    }
                    .hex {
                        float: right;
                        text-transform: uppercase;
                        display: block;
                    }
                }
            }
            &:last-child {
                li {
                    &.color {
                        padding: 10px 15px 11px;
                        margin: 0;
                        &.main-color {
                            .name {
                                display: block;
                                margin-bottom: 53px;
                                font-size: 2rem;
                            }
                        }
                        .hex {
                            float: right;
                            text-transform: uppercase;
                            display: block;
                        }
                    }
                }
            }
        }
        .dark {
            span {
                color: $body-color;
            }
        }
        span {
            color: rgba($white, .87);
            font-size: 14px;
            font-weight: 500;
            &.light-strong {
                color: $white;
            }
            &.dark {
                color: $body-color;
            }
        }
    }
    // helper class
    .bd-highlight,
    .width-example {
        background-color: rgba(86, 61, 124, 0.15);
        border: 1px solid rgba(86, 61, 124, 0.15);
    }
    .height-example-wrapper {
        background-color: rgba(255, 0, 0, 0.1);
        height: 100px;
        .height-example {
            background-color: rgba(0, 0, 255, .1);
            width: 120px;
        }
    }
    // font icons
    .fonticon-container {
        >.fonticon-wrap {
            float: left;
            width: 60px;
            height: 60px;
            line-height: 4.8rem;
            text-align: center;
            border-radius: 0.1875rem;
            margin-right: 1rem;
            margin-bottom: 1.5rem;
            >i {
                font-size: 2.28rem;
                &.fa {
                    font-size: 2.28rem !important;
                }
                transition: all .2s ease-in-out;
            }
            &.youtube {
                width: 100px;
            }
        }
        &:hover {
            i {
                color: theme-color('primary');
                font-size: 2.9rem;
                transform: scale(1.3);
            }
        }
        >.fonticon-classname,
        >.fonticon-unit {
            display: block;
            font-size: 1.5rem;
            line-height: 1.2;
        }
        >.fonticon-unit {
            font-size: 1rem;
            font-style: italic;
        }
    }
}

// buy now button
.buy-now {
    position: fixed;
    bottom: 4%;
    right: 5.8rem;
    z-index: 1039;
}

// scroll to top button
.scroll-top {
    position: fixed;
    z-index: 1039;
    bottom: 4%;
    right: 2rem;
    padding: 0.4rem 0.8rem;
}

@include media-breakpoint-down(xs) {
    // Media object -> smaller img in small screen
    #media-object {
        img {
            width: 45px;
            height: 45px;
        }
    }
    // carousel caption
    .carousel-caption {
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    // upload/dropzone -> message font size
    .dropzone.dropzone-area .dz-message {
        font-size: 1.5rem;
        &:before {
            font-size: 3rem;
            height: 50px;
        }
    }
}

// Modal -> form with icon
#iconForm {
    .form-control-position {
        top: 1px;
    }
}

//--------------- Documentation ---------------
.doc-sidebar-wrapper {
    position: fixed;
    right: 1rem;
    .doc-sidebar {
        margin-left: 4.5rem;
    }
    body.page-scrolled & {
        top: 1.5rem;
    }
}

ul.doc-sidebar-nav-list {
    list-style: none;
    padding-left: 0;
}

@include media-breakpoint-down(lg) {
    .doc-sidebar-wrapper .doc-sidebar {
        margin-left: 0.5rem;
    }
}

//--------------- Form ----------------
.logo-form {
    margin: 30px auto;
    width: 300px;
    text-align: center;
}

.logo-form img {
    margin: auto;
    max-width: 800px;
    width: 100%;
    position: relative;
}

.form-content {
    padding: 10px;
    border: solid #e6a707;
    border-top: unset;
    margin: 5vh auto;
    border-radius: 20px;
    width: calc( 100% - 100px);
    background: #e6a7074a;
}

.form-content .custom-switch.custom-switch-primary input[type=checkbox]:checked~label:before {
    border-color: #e6a707;
    background-color: #e6a707;
}

.form-content .ng-select .ng-select-container {
    // background: transparent;
}

.ng-select-single .ng-value {
    color: black;
}

.form-content label {
    color: white;
}

.text-white {
	color: #ffffff;
}
.subtitle-form {
    margin: 30px 0px 20px;
}
.subtitle-form h1 {
	text-align: center;
    color: #e6a707;
}

.subtitle-form h3 {
    color: #e6a707;
}

.form-card {
    background: black;
    margin: 0px;
}

.form-submit,
.wizardBtn {
    color: #e6a707;
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 0.35rem;
    padding: 0.4rem 1.25rem;
    text-transform: capitalize;
    margin: 10px 40px 10px 0px;
}

.form-submit:hover,
.wizardBtn:hover {
    color: white;
    background-color: #e6a707;
    border-color: #ffffff;
}

.recipeForm p {
    color: white;
}

.form-content tag {
    background: #0000008c !important;
    color: white !important;
}

.tagStyle input {
    background: #43300200;
    color: white;
}

.ng-tns-c185-6 input,
.tagStyle input {
    color: black;
}

.steps-indicators .done .step-indicator {
    background: green;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator {
    background: #e6a707;
}

aw-wizard-navigation-bar ul.steps-indicator li a .label {
    color: white;
}

.card-body {
    min-height: 85vh;
}

.fistStepTit {
    text-align: center;
}

.fistStepTit h3 {
    color: white;
    margin: 30px 0px;
}

.choiceBtn {
    text-align: center;
    font-size: 1.5em;
}

#btnNext1:disabled,
#btnNext2:disabled,
#btnNext3:disabled {
    cursor: no-drop !important;
    opacity: 0.65;
}

.stepControl {
    color: red;
}

::placeholder {
    color: rgb(145, 145, 145) !important;
    // color: white !important;
}